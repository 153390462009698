export default {
    "收益率": "Киреше ставкасы",
    "结束时间": "Аяктоо убактысы",
    "短期融资": "Кыска мөөнөттүү каржылоо",
    "记录": "Жазуулар",
    "转出本金": "Башкы капиталды чыгаруу",
    "转出收益": "Кирешени чыгаруу",
    "钱包余额": "Чөнтөк баланс",
    "天": "Күндөр",
    "请输入用户名": "Колдонуучунун атын киргизиңиз",
    "请输入密码": "Жупуну сөздү киргизиңиз",
    "提交": "Жиберүү",
    "立即开户": "Азыр катталыңыз",
    "联系客服": "Кардарларды тейлөө менен байланышуу",
    "联系客服2": "Кардарларды тейлөө менен байланышуу",
    "用户名": "Колдонуучу аты",
    "密码": "Жупуну сөз",
    "确认密码": "Жупуну сөздү тастыктаңыз",
    "请确认密码": "Жупуну сөздү тастыктаңыз",
    "邀请码": "Чакыруу коду",
    "请输入邀请码": "Чакыруу кодун киргизиңиз",
    "真是姓名(可选)": "Чыныгы аты-жөнү (опция)",
    "身份证号码(可选)": "ИД номер (опция)",
    "已经有一个帐户": "Жана бир аккаунт бар",
    "版权所有©2024TS股份有限公司保留所有权利": "Бардык укуктар 2024TS Коомдук Жабык Акционердик Коомунун укугун сактайт",
    "此网站支持IPv6访问": "Бул вебсайт IPv6 киришин колдойт",
    "在线用户": "Интернет колдонуучулар",
    "快速入口": "Тез кирүү",
    "支持全球USDT输入": "Дүйнө жүзү боюнча USDT киришин колдойт",
    "关于我们": "Биз жөнүндө",
    "帮助中心": "Жардам борбору",
    "市场": "Рынок",
    "产品趋势": "Продукциялардын тренди",
    "订单历史": "Заказ тарыхы",
    "我的账户": "Менин аккаунтум",
    "持仓": "Позициялар",
    "今日开业": "Бүгүнкү ачылыш",
    "昨天收盘": "Кечээки жабылыш",
    "最高": "Максимум",
    "最低": "Минимум",
    "K线": "K-челектери",
    "趋势": "Тренд",
    "挂单": "Күткөн заказдар",
    "数量": "Саны",
    "买涨价": "Кымбаттоо баасы",
    "买跌价": "Төмөндөө баасы",
    "时间": "Убакыт",
    "去向": "Баары",
    "买涨": "Кымбаттоо",
    "买跌": "Төмөндөө",
    "持仓详情": "Позициялардын толук маалыматы",
    "结算时间": "Кошумча убакыт",
    "购买时间": "Сатып алуу убактысы",
    "盈利": "Киреше",
    "没有更多数据": "Кошумча маалымат жок",
    "购买确认": "Сатып алууну тастыктоо",
    "名称": "Аты",
    "方向": "Чыгарылыш",
    "当前价格": "Учурдагы баа",
    "账户余额": "Аккаунт баланс",
    "秒": "Секунддар",
    "最小购买金额等于或大于": "Минимум сатып алуу суммасы",
    "收益": "Киреше",
    "取消": "Жокко чыгаруу",
    "确定": "Тастыктоо",
    "持仓中": "Позицияда",
    "历史持仓": "Тарыхы боюнча позициялар",
    "利润/损失/时间": "Киреше/жоготуу/убакыт",
    "产品/金额": "Продукциялар/Сумма",
    "加载中": "Жүктөлүп жатат",
    "充值": "Кошуу",
    "提款": "Алуулары",
    "财务详细信息": "Мамлекеттик маалыматтар",
    "实名认证": "Негизги адамды каттоо",
    "提款历史记录": "Алуулар тарыхы",
    "订单历史记录": "Заказ тарыхы",
    "我的团队": "Менин командам",
    "邀请": "Чакыруулар",
    "用户等级": "Колдонуучу деңгээли",
    "杠杆作用": "Көчүрүүчү аракет",
    "修改密码": "Жупуну сөздү өзгөртүү",
    "切换语言": "Тилди өзгөртүү",
    "退出登录": "Чыгып кетүү",
    "存入市场": "Рынокко салуу",
    "充值数量": "Кошуу суммасы",
    "登录密码": "Кирүү жупуну сөз",
    "提款密码": "Алуу жупуну сөз",
    "首次设置时，请输入4位提款密码": "Алгачкы орнотууда, 4 белгиден турган алуулар паролун киргизиңиз",
    "请重新输入4位数字密码": "4 сандык паролду кайрадан киргизиңиз",
    "新密码": "Жаңы жупуну сөз",
    "请仔细检查您的信息": "Маалыматыңызды текшерип чыгыңыз",
    "银行名称": "Банктын аты",
    "银行代码": "Банк коду",
    "银行账户": "Банк эсеби",
    "持卡人": "Карта ээсинин аты",
    "银行分行": "Банктын филиалы",
    "提款数量": "Алуу суммасы",
    "请输入提款数量": "Алуу суммасын киргизиңиз",
    "友情提示": "Достук боюнча эскертүү",
    "印花税": "Мөөр салыгы",
    "如果你有任何问题，请": "Эгерде суроолоруңуз болсо, сураныч",
    "时间": "Убакыт",
    "过滤": "Фильтр",
    "开始": "Баштоо",
    "结束": "Аяктоо",
    "请选择时间": "Убакытты тандаңыз",
    "交易类型": "Соода түрү",
    "投资结算": "Инвестициялык чечим",
    "在线充值": "Интернет аркылуу кошуу",
    "提款退款": "Алууларды кайтаруу",
    "投资支出": "Инвестициялык чыгымдар",
    "提款申请": "Алуу боюнча арыз",
    "提款成功": "Алуу ийгиликтүү",
    "重置": "Кайра орнотуулар",
    "实名制验证": "Реалдуу адамды текшерүү",
    "匿名用户": "Аноним колдонуучу",
    "真实姓名": "Чыныгы аты-жөнү",
    "请输入真实姓名": "Чыныгы аты-жөнүңүздү киргизиңиз",
    "身份证号码": "ИД номери",
    "请输入身份证号码": "ИД номериңизди киргизиңиз",
    "邀请二维码": "Чакыруу QR коду",
    "复制邀请链接": "Чакыруу шилтемесин көчүрүү",
    "复制成功": "Көчүрүү ийгиликтүү",
    "下属人数": "Төмөнкү кызматкерлер саны",
    "团队规模": "Командалык өлчөм",
    "团队余额": "Команда балансы",
    "团队帐户更改": "Командалык аккаунт өзгөртүүлөрү",
    "团队投资": "Командалык инвестициялар",
    "团队报告": "Командалык отчет",
    "状态": "Абал",
    "未解决": "Чечилген жок",
    "稳定的": "Туруктуу",
    "概述": "Кыскача маалымат",
    "总余额": "Жалпы баланс",
    "投资": "Инвестиция",
    "盈利能力": "Кирешелүүлүк",
    "存取款差异": "Кошуу/алуулар айырмасы",
    "总损益": "Жалпы киреше/жоготуу",
    "退出成功": "Чыгуу ийгиликтүү",
    "请先完成实名认证": "Реалдуу адамды каттоо процесси толук болгондон кийин өтүңүз",
    "绑定银行卡": "Банктын картасын байланыштыруу",
    "请输入4位数字密码以验证您的身份": "Идентификацияны текшерүү үчүн 4 сандык пароль киргизиңиз",
    "请绑定银行卡": "Банктын картасын байланыштырыңыз",
    "收入": "Киреше",
    "支出": "Чыгымдар",
    "价格": "Баа",
    "此功能尚未打开": "Бул функция азырынча ишке кирген жок",
    "固定": "Туруктуу",
    "操作": "Иш-аракет",
    "详情": "Толук маалымат",
    "复制失败": "Көчүрүү ийгиликсиз",
    "切换成功": "Өзгөртүү ийгиликтүү",
    "网上借款": "Интернет аркылуу карыз алуу",
    "公告": "Жарлык",
    "银行卡": "Банктын картасы",
    "请选择银行": "Банкты тандаңыз",
    "请填写充值金额": "Кошуу суммасын толтуруңуз",
    "提款地址": "Алуу дареги",
    "请输入提款地址": "Алуу дарегин киргизиңиз",
    "您还未绑定提款地址，请先绑定提款地址!": "Сиз али алуулар дарегин байланыштырып алган жоксуз, сураныч, алдыда байланыштырыңыз!",
    "USDT地址": "USDT дареги",
    "应转USDT金额": "USDT'ге которулууга тийиш болгон сумма",
    "客服": "Кардарларды тейлөө",
    "请先设置提款密码": "Алды менен алуулар паролун орнотуңуз",
    "余额": "Баланс",
    "格式不正确": "Формат туура эмес",
    "取款支出": "Алуу чыгымдары",
    "取款退回": "Алуулар кайтаруу",
    "充值成功": "Кошуу ийгиликтүү",
    "上次交易": "Акыркы соода",
    "APP下载": "Программаны жүктөп алуу",
    "佣金": "Комиссия",
    "账户余额R50000以下，1:1配比": "Аккаунт балансы R50000 төмөн, 1:1 салыштырма",
    "账户余额R50001-R100000，1:2配比": "Аккаунт балансы R50001-R100000, 1:2 салыштырма",
    "账户余额R100001-R200000，1:3配比": "Аккаунт балансы R100001-R200000, 1:3 салыштырма",
    "账户余额R200001-R500000，1:4配比": "Аккаунт балансы R200001-R500000, 1:4 салыштырма",
    "账户余额R500000以上，1:5配比": "Аккаунт балансы R500000 жогору, 1:5 салыштырма",
    "比如你的账户有R100000，那么平台会按照1:2的比例给你账户充值R200000，那么你的投资本金即为R300000": "Мисалы, сиздин аккаунтуңузда R100000 болсо, платформа 1:2 коэффициенти менен сиздин аккаунтуңузга R200000 кошот, ошондо сиздин инвестициялык негизги капиталыңыз R300000 болот",
    "完成45天的合约后，你在归还平台配比本金的同时，还需要支付10%的盈利佣金": "45 күндүк келишимди аяктагандан кийин, сиз платформанын кошуп берген негизги капиталын кайтарып берүүңүз керек, ошондой эле 10% киреше комиссиясын төлөшүңүз керек",
    "当账户余额亏损至本金的30%，那么系统将自动解除合约": "Эгерде аккаунт балансы негизги капиталдын 30% жоготсо, анда система келишимди автоматтык түрдө токтотот",
    "说明": "Түшүндүрмө",
    "点击参与": "Катышууну басыңыз",
    "参与成功": "Катышуу ийгиликтүү",
    "已参与": "Катышуу аяктады",
    "正面": "Алдынкы",
    "反面": "Артындагы",
    "请上传证件": "Документтерди жүктөөңүздү суранабыз",
    "系统错误，请稍后尝试！": "Жүйөлүк каталык, сураныч, бир аз убакыттан кийин аракет кылып көрүңүз",
    "全民代理": "Жалпы агенттер",
    "区域总代理—需要拥有多年品牌推广经验，以及管理能力，需经平台审核资质并缴纳50万美金的保证金,可享受代理团队总收益的2%": "Аймактык жалпы агент—брендди жылдыруу тажрыйбасы жана башкаруу жөндөмдүүлүгү талап кылынат, платформа аркылуу текшерилип, 50 миң доллар кепилдик төлөө керек, агенттик топтун жалпы кирешесинин 2%ын алуу укугуна ээ болот",
    "一级代理人，享受下级收益的10%": "Биринчи деңгээлдеги агент, төмөнкү деңгээлдеги кирешенин 10%ын алат",
    "二级代理人，享受下级收益的5%": "Экинчи деңгээлдеги агент, төмөнкү деңгээлдеги кирешенин 5%ын алат",
    "三级代理人，享受下级收益的3%": "Үчүнчү деңгээлдеги агент, төмөнкү деңгээлдеги кирешенин 3%ын алат",
    "投资币": "Инвестициялык монета",
    "累计理财收益": "Жалпы каржылык киреше",
    "凭证": "Тастыктамалар",
    "请先完成实名认证": "Реалдуу адамды каттоо процесси толук болгондон кийин өтүңүз",
    "请上传凭证": "Тастыктамаларды жүктөңүз",
    "只有完成一个周期的订单后，才可以继续另外一个新的订单": "Бир цикли бүтүп анан жаңы заказ кылуу мүмкүнчүлүгү бар",
    "转账到金融账户后，每天至少需要20个小时才能产生收入": "Молукма финансынын эсепке которулгандан кийин күнүнө 20 сааттан кем эмес киреше алынышы керек",
    "财务管理收入将在财务管理期结束后提取，财务管理金额在此期间不能提取": "Финансылык башкаруу кирешеси қаржылык башкаруу мөөнөтү аяктаган соң чыгарылат, ал убакыт аралыгында финансы каржылыктарын алуу мүмкүн эмес",
    "未认证": "Текшерилбеген",
    "待审核": "Текшерүүдө",
    "已认证": "Текшерилген",
    "已驳回": "Жокко чыгарылган",
    "提示": "Эскертүү",
    "登录": "Кирүү",
    "身份证/护照正面": "ИД/Паспордун алдындагы бет",
    "身份证/护照反面": "ИД/Паспордун артындагы бет",
    "体验金": "Тажрыйба Алтын",
    "金额": "Сумма",
    "未领取": "Алына элек",
    "领取中": "Алууда",
    "已过期": "Мөөнөтү бүткөн",
    "领取时间": "Алуу убактысы",
    "过期时间": "Мөөнөтү бүтүү убактысы",
    "领取": "Алуу",
    "提款请绑定": "Акча алуу үчүн байлоо керек",
    "最低提款金额": "Эң төмөнкү алуу суммасы",
    "下级返佣记录": "Төмөнкү деңгээлдеги комиссия кайтаруу жазуусу"
}
export default {
    "收益率": "收益率",
    "结束时间": "結束時間",
    "短期融资": "短期理財",
    "记录": "記錄",
    "转出本金": "轉出本金",
    "转出收益": "轉出收益",
    "钱包余额": "錢包餘額",
    "天": "天",
    "请输入用户名": "請輸入用戶名",
    "请输入密码": "請輸入密碼",
    "提交": "提交",
    "立即开户": "立即開戶",
    "联系客服": "聯繫客服",
    "联系客服2": "聯繫客服",
    "用户名": "用戶名",
    "密码": "密碼",
    "确认密码": "確認密碼",
    "请确认密码": "請確認密碼",
    "邀请码": "邀請碼",
    "请输入邀请码": "請輸入邀請碼",
    "真是姓名(可选)": "真實姓名（可選）",
    "身份证号码(可选)": "身份證號碼（可選）",
    "已经有一个帐户": "已經有一個帳戶",
    "版权所有©2024TS股份有限公司保留所有权利": "版權所有©2024 TS股份有限公司保留所有權利",
    "此网站支持IPv6访问": "此網站支持IPv6訪問",
    "在线用户": "在線用戶",
    "快速入口": "快速入口",
    "支持全球USDT输入": "支持全球USDT輸入",
    "关于我们": "關於我們",
    "帮助中心": "幫助中心",
    "市场": "市場",
    "产品趋势": "產品趨勢",
    "订单历史": "訂單歷史",
    "我的账户": "我的帳戶",
    "持仓": "持倉",
    "今日开业": "今日開業",
    "昨天收盘": "昨日收盤",
    "最高": "最高",
    "最低": "最低",
    "K线": "K線",
    "趋势": "趨勢",
    "挂单": "掛單",
    "数量": "數量",
    "买涨价": "買漲價",
    "买跌价": "買跌價",
    "时间": "時間",
    "去向": "方向",
    "买涨": "買漲",
    "买跌": "買跌",
    "持仓详情": "持倉詳情",
    "结算时间": "結算時間",
    "购买时间": "購買時間",
    "盈利": "盈利",
    "没有更多数据": "沒有更多數據",
    "购买确认": "購買確認",
    "名称": "名稱",
    "方向": "方向",
    "当前价格": "當前價格",
    "账户余额": "帳戶餘額",
    "秒": "秒",
    "最小购买金额等于或大于": "最小購買金額等於或大於",
    "收益": "收益",
    "取消": "取消",
    "确定": "確認",
    "持仓中": "持倉中",
    "历史持仓": "歷史持倉",
    "利润/损失/时间": "利潤/損失/時間",
    "产品/金额": "產品/金額",
    "加载中": "加載中",
    "充值": "充值",
    "提款": "提款",
    "财务详细信息": "財務詳細信息",
    "实名认证": "實名認證",
    "提款历史记录": "提款歷史記錄",
    "订单历史记录": "訂單歷史記錄",
    "我的团队": "我的團隊",
    "邀请": "邀請",
    "用户等级": "用戶等級",
    "杠杆作用": "槓桿作用",
    "修改密码": "修改密碼",
    "切换语言": "切換語言",
    "退出登录": "退出登錄",
    "存入市场": "存入市場",
    "充值数量": "充值數量",
    "登录密码": "登錄密碼",
    "提款密码": "提款密碼",
    "首次设置时，请输入4位提款密码": "首次設置時，請輸入4位提款密碼",
    "请重新输入4位数字密码": "請重新輸入4位數字密碼",
    "新密码": "新密碼",
    "请仔细检查您的信息": "請仔細檢查您的信息",
    "银行名称": "銀行名稱",
    "银行代码": "銀行代碼",
    "银行账户": "銀行賬戶",
    "持卡人": "持卡人",
    "银行分行": "銀行分行",
    "提款数量": "提款數量",
    "请输入提款数量": "請輸入提款數量",
    "友情提示": "友情提示",
    "印花税": "印花稅",
    "如果你有任何问题，请": "如果你有任何問題，請",
    "时间": "時間",
    "过滤": "篩選",
    "开始": "開始",
    "结束": "結束",
    "请选择时间": "請選擇時間",
    "交易类型": "交易類型",
    "投资结算": "投資結算",
    "在线充值": "在線充值",
    "提款退款": "提款退款",
    "投资支出": "投資支出",
    "提款申请": "提款申請",
    "提款成功": "提款成功",
    "重置": "重置",
    "实名制验证": "實名制驗證",
    "匿名用户": "匿名用戶",
    "真实姓名": "真實姓名",
    "请输入真实姓名": "請輸入真實姓名",
    "身份证号码": "身份證號碼",
    "请输入身份证号码": "請輸入身份證號碼",
    "邀请二维码": "邀請二維碼",
    "复制邀请链接": "複製邀請鏈接",
    "复制成功": "複製成功",
    "下属人数": "下屬人數",
    "团队规模": "團隊規模",
    "团队余额": "團隊餘額",
    "团队帐户更改": "團隊帳戶更改",
    "团队投资": "團隊投資",
    "团队报告": "團隊報告",
    "状态": "狀態",
    "未解决": "未解決",
    "稳定的": "穩定的",
    "概述": "概述",
    "总余额": "總餘額",
    "投资": "投資",
    "盈利能力": "盈利能力",
    "存取款差异": "存取款差異",
    "总损益": "總損益",
    "退出成功": "退出成功",
    "请先完成实名认证": "請先完成實名認證",
    "绑定银行卡": "綁定銀行卡",
    "请输入4位数字密码以验证您的身份": "請輸入4位數字密碼以驗證您的身份",
    "请绑定银行卡": "請綁定銀行卡",
    "收入": "收入",
    "支出": "支出",
    "价格": "價格",
    "此功能尚未打开": "此功能尚未開啟",
    "固定": "固定",
    "操作": "操作",
    "详情": "詳情",
    "复制失败": "複製失敗",
    "切换成功": "切換成功",
    "网上借款": "網上借款",
    "公告": "公告",
    "银行卡": "銀行卡",
    "请选择银行": "請選擇銀行",
    "请填写充值金额": "請填寫充值金額",
    "提款地址": "提款地址",
    "请输入提款地址": "請輸入提款地址",
    "您还未绑定提款地址，请先绑定提款地址!": "您還未綁定提款地址，請先綁定提款地址！",
    "USDT地址": "USDT地址",
    "应转USDT金额": "應轉USDT金額",
    "客服": "客服",
    "请先设置提款密码": "請先設置提款密碼",
    "余额": "餘額",
    "格式不正确": "格式不正確",
    "取款支出": "取款支出",
    "取款退回": "取款退回",
    "充值成功": "充值成功",
    "上次交易": "上次交易",
    "APP下载": "APP下載",
    "佣金": "佣金",
    "账户余额R50000以下，1:1配比": "賬戶餘額R50000以下，1:1配比",
    "账户余额R50001-R100000，1:2配比": "賬戶餘額R50001-R100000，1:2配比",
    "账户余额R100001-R200000，1:3配比": "賬戶餘額R100001-R200000，1:3配比",
    "账户余额R200001-R500000，1:4配比": "賬戶餘額R200001-R500000，1:4配比",
    "账户余额R500000以上，1:5配比": "賬戶餘額R500000以上，1:5配比",
    "比如你的账户有R100000，那么平台会按照1:2的比例给你账户充值R200000，那么你的投资本金即为R300000": "比如你的賬戶有R100000，那麼平台會按照1:2的比例給你賬戶充值R200000，那麼你的投資本金即為R300000",
    "完成45天的合约后，你在归还平台配比本金的同时，还需要支付10%的盈利佣金": "完成45天的合約後，你在歸還平台配比本金的同時，還需要支付10%的盈利佣金",
    "当账户余额亏损至本金的30%，那么系统将自动解除合约": "當賬戶餘額虧損至本金的30%，那麼系統將自動解除合約",
    "说明": "說明",
    "点击参与": "點擊參與",
    "参与成功": "參與成功",
    "已参与": "已參與",
    "正面": "正面",
    "反面": "反面",
    "请上传证件": "請上傳證件",
    "系统错误，请稍后尝试！": "系統錯誤，請稍後嘗試！",
    "全民代理": "全民代理",
    "区域总代理—需要拥有多年品牌推广经验，以及管理能力，需经平台审核资质并缴纳50万美金的保证金,可享受代理团队总收益的2%": "區域總代理—需要擁有多年品牌推廣經驗，以及管理能力，需經平臺稽核資質並繳納50萬美金的保證金，可享受代理團隊總收益的2%",
    "一级代理人，享受下级收益的10%": "一級代理人，享受下級收益的10%",
    "二级代理人，享受下级收益的5%": "二級代理人，享受下級收益的5%",
    "三级代理人，享受下级收益的3%": "三級代理人，享受下級收益的3%",
    "投资币": "投資幣",
    "累计理财收益": "累計理財收益",
    "凭证": "憑證",
    "请先完成实名认证": "請先完成實名認證",
    "请上传凭证": "請上傳憑證",
    "只有完成一个周期的订单后，才可以继续另外一个新的订单": "只有完成一個週期的訂單後，才可以繼續另外一個新的訂單",
    "转账到金融账户后，每天至少需要20个小时才能产生收入": "轉移到金融帳戶後，每天至少需要20個小時才能產生收入。",
    "财务管理收入将在财务管理期结束后提取，财务管理金额在此期间不能提取": "財務管理收入將在財務管理期結束後選取，財務管理金額在此期間不能選取。",
    "未认证": "未認證",
    "待审核": "待稽核",
    "已认证": "已認證",
    "已驳回": "已駁回",
    "提示": "提示",
    "登录": "登錄",
    "身份证/护照正面": "身份證/護照正面",
    "身份证/护照反面": "身份證/護照反面",
    "体验金": "體驗金",
    "金额": "金額",
    "未领取": "未領取",
    "领取中": "領取中",
    "已过期": "已過期",
    "领取时间": "領取時間",
    "过期时间": "過期時間",
    "领取": "領取",
    "提款请绑定": "提款請綁定",
    "最低提款金额": "最低提款金額",
    "下级返佣记录": "下級返傭記錄"
}